import React, {
  FC,
  useEffect,
  useMemo,
  useRef,
  useState,
  Suspense,
} from 'react';
import {
  useEnvironment,
  useExperiments,
  useTranslation,
  WidgetProps,
  useErrorBoundary,
} from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { classes, st } from './Widget.st.css';
import { FormControllerActions } from '../Actions/actions';
import { FormActionsProvider } from '../Hooks/useFormActions';
import { FormRef, SubmissionResponse } from '@wix/forms-ui/types';
import FormInputs from './FormInputs/FormInputs';
import { UserSelection } from './UserSelection/UserSelection';
import { FormState } from '../../../utils/state/initialStateFactory';
import BookingDetails from './BookingDetails/BookingDetails';
import BookButton from './BookButton/BookButton';
import { DateTimeFormatter } from '@wix/bookings-date-time';
import EmptyStatePage from './EmptyStatePage/EmptyStatePage';
import { EditorContextProvider } from '../Hooks/useEditorContext';
import { EmptyStateErrorType, GenericErrorType } from '../../../types/errors';
import { PaymentSelection } from './PaymentSelection/PaymentSelection';
import BackButton from './BackButton/BackButton';
import {
  getPaymentOptions,
  isNotOffered,
  isOfferedAsPricingPlanOnlyWithoutPlansConnected,
} from '../../../utils/payment/payment';
import { WidgetDataHooks } from './dataHooks';
import Coupon from './Coupon/Coupon';
import { PaymentSummary } from './PaymentSummary/PaymentSummary';
import {
  CartFlow,
  PaymentOption,
  ReservedPaymentOptionIds,
} from '../../../types/types';
import { getErrorByType, hasErrorOfType } from '../../../utils/errors/errors';
import {
  BookingRequestKeyMappings,
  getFieldFromSchema,
} from '../../../utils/mappers/form-submission.mapper';
import { FormStatus } from '../../../types/form-state';
import { FormComponentContextProvider } from '../Hooks/useFormComponentContext';
import PriceOptionDropdown, {
  PriceOptionNumberOfParticipants,
} from './PriceOptionDropdown/PriceOptionDropdown';
import { CustomOption } from '../../../types/dynamicPrice';
import { ExperimentsConsts } from '../../../consts/experiments';
import { BookButtonDataHooks } from './BookButton/dataHooks';
import CartModal from './CartModal/CartModal';

const Dialog = React.lazy(() => import('./Dialog/Dialog'));
const Header = React.lazy(() => import('./Header/Header'));
const Toast = React.lazy(() => import('./Toast/Toast'));
const CancellationPolicy = React.lazy(
  () => import('./CancellationPolicy/CancellationPolicy'),
);

export type FormComponentActions = {
  submitForm?: () => SubmissionResponse;
};
export type FormActions = FormControllerActions & FormComponentActions;

export type ControllerProps = {
  actions: FormControllerActions;
} & FormState;

const Widget: FC<WidgetProps<ControllerProps>> = ({
  actions,
  service,
  slotAvailability,
  businessInfo,
  pricingPlanDetails,
  memberships,
  isPricingPlanInstalled,
  isMemberAreaInstalled,
  isCart,
  selectedPaymentOptionId,
  paymentDetails,
  couponInfo,
  memberDetails,
  errors,
  editorContext,
  status,
  overrideDefaultFieldsValues,
  dialog,
  formInputs,
  selectedPaymentType,
  isBookingsOnEcom,
  cartModal,
  dynamicPriceInfo,
}) => {
  useEffect(() => {
    if (status === FormStatus.INITIALIZING) {
      actions.initializeWidget();
    }
  }, []);

  const [
    showDynamicPriceDropdownError,
    setShowDynamicPriceDropdownError,
  ] = useState<boolean>(false);

  const [
    dynamicPriceDropdownErrorMessage,
    setDynamicPriceErrorMessage,
  ] = useState<string>('');

  const currentMaxNumberOfParticipants =
    (selectedPaymentOptionId === ReservedPaymentOptionIds.SingleSession
      ? service?.maxNumberOfParticipantsWithoutPP ||
        service?.maxNumberOfParticipants
      : service?.maxNumberOfParticipants) || 1;

  const { experiments } = useExperiments();
  const isDynamicPricingCustomUoU = experiments.enabled(
    ExperimentsConsts.DynamicPricingCustomUoU,
  );

  const isShowCancellationPolicyOnFormEnabled = experiments.enabled(
    ExperimentsConsts.ShowCancellationPolicyOnForm,
  );

  const formRef = useRef<FormRef>();
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { error } = useErrorBoundary();
  const isDynamicPriceDropdownValid = () => {
    const selectedNumberOfParticipants =
      dynamicPriceInfo?.selectedVariants?.reduce(
        (acc, nextVariant) => acc + (nextVariant.numberOfParticipants || 0),
        0,
      ) || 0;

    const isAtLeastOneParticipantSelected = selectedNumberOfParticipants > 0;
    const isEqualToOrLessThanMaxParticipantsAllowed =
      selectedNumberOfParticipants <= currentMaxNumberOfParticipants!;

    const isDropdownValid =
      isAtLeastOneParticipantSelected &&
      isEqualToOrLessThanMaxParticipantsAllowed;

    if (!isDropdownValid) {
      setShowDynamicPriceDropdownError(true);
      if (!isAtLeastOneParticipantSelected) {
        setDynamicPriceErrorMessage(
          t('app.form-inputs.validation-errors.required-field'),
        );
      } else {
        setDynamicPriceErrorMessage(
          t('app.booking-details.price-not-enough-sessions-error-message', {
            planName: selectedPaymentOption.label,
          }),
        );
      }
    }
    return !!isAtLeastOneParticipantSelected;
  };
  const submitForm = isDynamicPricingCustomUoU
    ? () => {
        const submissionResponse = formRef?.current?.submit();
        const isDynamicPricingCustom = !!dynamicPriceInfo?.customOptions
          ?.length;
        if (isDynamicPricingCustom) {
          submissionResponse!.state.valid =
            submissionResponse?.state.valid! && isDynamicPriceDropdownValid();
        }
        return submissionResponse;
      }
    : () => formRef?.current?.submit();
  const settings = useSettings();
  const slot = slotAvailability?.slot!;
  const dateRegionalSettingsLocale = businessInfo?.dateRegionalSettingsLocale!;
  const { numberOfParticipants } = { ...formInputs };
  const processingStatuses = [
    FormStatus.INITIALIZING,
    FormStatus.PROCESSING_USER_DETAILS,
    FormStatus.SSR,
  ];
  const isProcessing = processingStatuses.includes(status);

  const shouldShowEmptyStatePage = () =>
    isProcessing ||
    hasErrorOfType({ errorType: EmptyStateErrorType, errors }) ||
    error;

  const dateAndTimeFormatter = useMemo(
    () => new DateTimeFormatter(dateRegionalSettingsLocale),
    [dateRegionalSettingsLocale],
  );
  const isDynamicPreferenceType = dynamicPriceInfo?.isDynamicPreferenceType!;

  const paymentOptions: PaymentOption[] = useMemo(
    () =>
      getPaymentOptions({
        servicePayment: service?.payment,
        pricingPlanDetails,
        memberships,
        isPricingPlanInstalled,
        dateAndTimeFormatter,
        numberOfParticipants,
        dateRegionalSettingsLocale,
        isDynamicPreferenceType,
        t,
        settings,
      }),
    [
      service?.payment,
      memberships,
      pricingPlanDetails,
      dateAndTimeFormatter,
      isPricingPlanInstalled,
      numberOfParticipants,
      dateRegionalSettingsLocale,
      isDynamicPreferenceType,
      t,
      settings,
    ],
  );

  const selectedPaymentOption = paymentOptions.find(
    (paymentOption) => paymentOption.id === selectedPaymentOptionId,
  )!;

  if (shouldShowEmptyStatePage()) {
    return <EmptyStatePage isProcessing={isProcessing} />;
  }

  const isPPServiceWithoutPPConnectedEnabled = experiments.enabled(
    ExperimentsConsts.PPServiceWithoutPPConnected,
  );
  if (
    isPPServiceWithoutPPConnectedEnabled &&
    (isOfferedAsPricingPlanOnlyWithoutPlansConnected(service?.payment) ||
      isNotOffered(service?.payment))
  ) {
    return (
      <EmptyStatePage
        isProcessing={isProcessing}
        title="app.empty-state-page.no-pp.title"
        subtitle="app.empty-state-page.no-pp.subtitle"
      />
    );
  }

  const toastError = getErrorByType({
    errors,
    errorType: GenericErrorType,
  });

  const showLoader = status === FormStatus.PROCESSING_BOOK_REQUEST;

  const canBookMultipleParticipants = !!getFieldFromSchema(
    service.formSchema,
    BookingRequestKeyMappings.NO_OF_PARTICIPANTS,
  );

  const showDynamicPriceDropdownPrices =
    selectedPaymentOptionId === ReservedPaymentOptionIds.SingleSession;
  return (
    <EditorContextProvider value={editorContext} key="form-main-widget">
      <FormActionsProvider value={{ ...actions, submitForm }}>
        <FormComponentContextProvider
          value={{
            isBookingsOnEcom,
          }}
        >
          <Suspense fallback={<div></div>}>
            <div
              className={st(classes.root, {
                isMobile,
                isProcessing: showLoader,
              })}
              data-hook={WidgetDataHooks.MAIN_CONTAINER}
            >
              <div className={classes.wrapper}>
                {showLoader ? (
                  <div
                    className={st(classes.blanket)}
                    data-hook={WidgetDataHooks.BLANKET}
                  />
                ) : null}
                {toastError ? (
                  <Toast
                    toastError={toastError}
                    numberOfParticipants={numberOfParticipants}
                  />
                ) : null}
                {cartModal?.status ? (
                  <CartModal cartModalStatus={cartModal?.status} />
                ) : null}
                <BackButton />
                <div className={classes.body}>
                  <div className={classes.formWrapper}>
                    <Header {...service.formHeader} />
                    {isMemberAreaInstalled && (
                      <UserSelection memberDetails={memberDetails} />
                    )}
                    <FormInputs
                      formSchema={service.formSchema}
                      formRef={formRef}
                      memberDetails={memberDetails}
                      overrideDefaultFieldsValues={overrideDefaultFieldsValues}
                    />
                    <PaymentSelection
                      paymentOptions={paymentOptions}
                      selectedPaymentOptionId={selectedPaymentOptionId}
                      numberOfParticipants={numberOfParticipants}
                      dateRegionalSettingsLocale={dateRegionalSettingsLocale}
                      selectedPaymentType={selectedPaymentType}
                      paymentTypes={service?.paymentTypes}
                    />
                    {dynamicPriceInfo?.customOptions?.map(
                      (customOption: CustomOption) => {
                        return (
                          <PriceOptionDropdown
                            showPrice={showDynamicPriceDropdownPrices}
                            options={customOption.options}
                            onDropdownClose={(
                              priceOptionData: PriceOptionNumberOfParticipants[],
                            ) => {
                              actions.onSelectedVariants(
                                priceOptionData,
                                customOption.optionId,
                              );
                              setShowDynamicPriceDropdownError(false);
                              setDynamicPriceErrorMessage('');
                            }}
                            maxParticipants={currentMaxNumberOfParticipants!}
                            disabled={
                              status === FormStatus.PROCESSING_PAYMENT_DETAILS
                            }
                            label={customOption.name}
                            error={showDynamicPriceDropdownError}
                            errorMessage={dynamicPriceDropdownErrorMessage}
                          />
                        );
                      },
                    )}
                  </div>
                  <div className={classes.sidebar}>
                    <div className={classes.floatingContainer}>
                      <BookingDetails
                        service={service}
                        slot={slot}
                        dateRegionalSettingsLocale={dateRegionalSettingsLocale}
                      />
                      <Coupon
                        couponInfo={couponInfo}
                        servicePayment={service.payment}
                        selectedPaymentOptionId={selectedPaymentOptionId}
                        errors={errors}
                        status={status}
                      />
                      <PaymentSummary
                        dateRegionalSettingsLocale={dateRegionalSettingsLocale}
                        servicePayment={paymentDetails}
                        selectedPaymentOption={selectedPaymentOption}
                        numberOfParticipants={numberOfParticipants}
                        showPricePerParticipant={canBookMultipleParticipants}
                        appliedCoupon={couponInfo.appliedCoupon}
                        status={status}
                        dynamicPriceInfo={dynamicPriceInfo}
                      />
                      {isCart &&
                      selectedPaymentOption.id !==
                        ReservedPaymentOptionIds.BuyAPricingPlan ? (
                        <>
                          <BookButton
                            dataHook={BookButtonDataHooks.CART_ADD_TO_CART_CTA}
                            isCart={isCart}
                            cartFlow={CartFlow.ADD_TO_CART}
                            isPendingApprovalFlow={
                              service.isPendingApprovalFlow
                            }
                            actionLabels={service.actionLabels}
                            paymentTypes={service.paymentTypes}
                            selectedPaymentOption={selectedPaymentOption}
                            status={status}
                            errors={errors}
                            paymentDetails={paymentDetails}
                          />
                        </>
                      ) : (
                        <BookButton
                          isPendingApprovalFlow={service.isPendingApprovalFlow}
                          actionLabels={service.actionLabels}
                          paymentTypes={service.paymentTypes}
                          selectedPaymentOption={selectedPaymentOption}
                          status={status}
                          errors={errors}
                          paymentDetails={paymentDetails}
                        />
                      )}
                      {isShowCancellationPolicyOnFormEnabled && (
                        <CancellationPolicy
                          policy={businessInfo?.cancellationPolicy}
                        />
                      )}
                    </div>
                  </div>
                </div>
                {dialog ? <Dialog {...dialog.props} /> : null}
              </div>
            </div>
          </Suspense>
        </FormComponentContextProvider>
      </FormActionsProvider>
    </EditorContextProvider>
  );
};

export default Widget;
