export const ExperimentsConsts = {
  UoUTemplateModeModal: 'specs.bookings.UoUTemplateModeModal',
  DynamicPricingUoU: 'specs.bookings.dynamicPricingUoU',
  DynamicPricingCustomUoU: 'specs.bookings.dynamicPricingCustomUoU',
  AlwaysShowComplexPhoneField: 'specs.bookings.AlwaysShowComplexPhoneField',
  FixDurationOver24Hours: 'specs.bookings.FixDurationOver24Hours',
  FixFormUoUCheckboxLink: 'specs.bookings.FixFormUoUCheckboxLink',
  DayfulOverrideEcomContinueShoppingUrl:
    'specs.bookings.DayfulOverrideEcomContinueShoppingUrl',
  FixFormSettingsSlowness: 'specs.bookings.FixFormSettingsSlowness',
  ShowCancellationPolicyOnForm: 'specs.booking.ShowCancellationPolicyOnForm',
  PPServiceWithoutPPConnected: 'specs.bookings.PPServiceWithoutPPConnected',
  ImproveFormPerformance: 'specs.bookings.ImproveFormPerformance',
  Cart: 'specs.bookings.cart',
  RedirectToNewBoBookingsForm: 'specs.bookings.BFYoshiMigration',
};
